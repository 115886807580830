import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src477529347/src/autopatcher-docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`This guide goes through all the basic steps that are needed to get AutoPatcher ready and patching your machines.`}</p>
    <h2 {...{
      "id": "step-1---get-access-to-autopatcher"
    }}>{`📄Step 1 - Get access to AutoPatcher`}</h2>
    <p>{`Use the AutoPatcher `}<a parentName="p" {...{
        "href": "http://autopatcher.nordcloudapp.com"
      }}>{`User Interface`}</a>{`.`}</p>
    <p><a parentName="p" {...{
        "href": "/setting-up-permissions"
      }}>{`Here`}</a>{` is how to set up the access and permissions.`}</p>
    <h2 {...{
      "id": "step-2---set-up-ssm-on-target-machines-and-target-accounts"
    }}>{`💻Step 2 - Set up SSM on target machines and target accounts`}</h2>
    <p>{`AutoPatcher uses AWS SSM to access machines which are going to be patched. To make a machine accessible for AutoPatcher via SSM you need to install an SSM agent on the machine. `}<a parentName="p" {...{
        "href": "/setting-up-ssm"
      }}>{`Read more`}</a></p>
    <h2 {...{
      "id": "step-3---add-machines-to-auto-patcher"
    }}>{`💻Step 3 - Add machines to Auto Patcher`}</h2>
    <p>{`A machine in AutoPatcher describes not just a machine, but also a set of parameters required to patch the machine, like what type of OS it runs, what category of patches are required and how can AutoPatcher access the machine. `}<a parentName="p" {...{
        "href": "/adding-machine"
      }}>{`Read more`}</a></p>
    <p><img alt="quickstart-3" src={require("../assets/quickstart-3.png")} /></p>
    <h2 {...{
      "id": "-step-4---set-up-notification"
    }}>{`🛎 Step 4 - Set up notification`}</h2>
    <p>{`Configure notifications for your patching and stay up to date about patching status or action to be taken in your channel: (slack, pager duty, email). `}<a parentName="p" {...{
        "href": "/notifications"
      }}>{`Read more`}</a></p>
    <p><img alt="quickstart-4" src={require("../assets/quickstart-4.png")} /></p>
    <h2 {...{
      "id": "️step-5---optional-add-pre--post-patching-actions---hooks"
    }}>{`⚡️Step 5 - (Optional) add Pre & Post Patching actions - Hooks`}</h2>
    <p>{`If you need to execute some actions before or/and after update you can use `}<strong parentName="p">{`Pre&Post Actions Auto-Patcher feature`}</strong>{`. It allows you to specify list of AWS Lambda ARNs or Azure Functions which should be executed before or/and after update. `}<a parentName="p" {...{
        "href": "/hooks"
      }}>{`Read more`}</a></p>
    <h2 {...{
      "id": "-step-6---schedule-a-patching-plan"
    }}>{`📄 Step 6 - Schedule a patching plan`}</h2>
    <p>{`AutoPatcher has it's own scheduler implemented. Choose the best scheduling way for your need and automate patching. `}<a parentName="p" {...{
        "href": "/scheduling-patching"
      }}>{`Read more`}</a></p>
    <p><img alt="quickstart-6" src={require("../assets/quickstart-6.png")} /></p>
    <h2 {...{
      "id": "step-7---patching-report"
    }}>{`📅Step 7 - Patching Report`}</h2>
    <p>{`For each patching event there is a possibility to see a report of what packages were installed, updated or removed on each machine during execution of the event. `}<a parentName="p" {...{
        "href": "/reports"
      }}>{`Read more`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      